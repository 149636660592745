<template>
   <section class="admin_users h-100">
      <div class="h-100">
         <div class="grid-margin h-100">
            <div class="h-100 container">
                
                <div class='h-100 row py-5'>
                    <!--cdata.business_logic.user_list-->
                    <p style="font-size: 24px; font-weight: 500;">Total: {{cdata.business_logic.user_list.length}}</p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Username</th>
                                <th>Class</th>
                                <th>lid</th>
                                <th>Date Reg</th>
                                <th>Activity</th>
                                <th>UTM data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in cdata.business_logic.user_list" :key="user.id">
                                <td>{{user.rid}}</td>
                                <td>{{user.name}}</td>
                                <td>{{user.classnum}}</td>
                                <td>{{user.lid}}</td>
                                <td>{{user.date_reg}}</td>
                                <td>{{user.mysql_v}}</td>
                                <td>{{user.utm_data}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
         </div>
      </div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import axios from 'axios';
    
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "admin_users", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth
                                    }
                        },
                    },
                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                    user_list: [],
                },
            }

    export default {
        name: 'admin_users', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },
        
        
        
        
        mounted() {
                    var params = {
                        method: "get_all_auth_for_admin",
                        data: {
                        }
                    };
                    axios.post(this.$root.cnf['server_action'], params)
                            .then(response =>
                            {
                                this.cdata.business_logic.user_list = response['data']['data'];
//                                console.log(response['data']);
                            })
                            .catch(error => {
                                console.log(error);
                                alert("error, please reload the page");
                            }).then(function () { });
        },





    }
</script>

<style lang="scss" scoped>

</style>

